import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import React, { Component } from 'react'

export default class Page extends Component {
    render() {
        console.log(this.props.data)
        return (
            <Layout container={true}>
                <SEO title="Cookies" />
                <h1 className="header-page">Cookies</h1>
                <div>
                    Diese Website verwendet Cookies – nähere Informationen dazu und zu Ihren Rechten als Benutzer finden Sie in unserer Datenschutzerklärung am Ende der Seite. Klicken Sie auf „Ich stimme zu“, um Cookies zu akzeptieren und direkt unsere Website besuchen zu können.
                </div>
                <br/>
                <br/>
                <br/>
            </Layout >
        )
    }
}